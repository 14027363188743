import React from "react";
import { Link } from "react-router-dom";

function HomePageAboutUs() {
  return (
    <div style={{ marginTop: 150, marginBottom: 300 }}>
      <div className="underLine"></div>
      <Link to="/about-us" className="HomePage-section-title">
        Learn more about ServeSavvy
      </Link>

      <div className="AUmissionPromisesTitles">
        <div className="AUmissionPromisesDesc">
          <h3>Elevating Restaurant Industry Excellence</h3>
          <p>
            Learn about our mission to transform how restaurants operate and our
            unwavering promise to our clients. At ServeSavvy, we're dedicated to
            enhancing operational efficiency and customer satisfaction through
            innovative technology solutions. Our tools are designed to
            streamline restaurant management so that you can focus on delivering
            exceptional culinary experiences. Partner with us and experience a
            service that's as reliable as it is revolutionary." This format sets
            a professional tone, highlighting both the mission and promise while
            inviting potential clients to learn more about the benefits of
            partnering with your company.
          </p>
        </div>
        <div className="AUmissionPromises">
          <section className=" missionSection">
            <h3>Our Mission</h3>
            <p>
              At ServeSavvy, our core mission is to empower restaurants of every
              size, enabling them to achieve unparalleled operational efficiency
              and elevate their customer service to new heights. Our robust
              application has undergone rigorous real-world testing to ensure
              reliability under diverse operational demands. It offers a
              comprehensive suite of features designed to meet the unique needs
              of both users and administrators. From seamless automated order
              management systems to in-depth analytics and efficient user
              account management, our solution covers all bases. This allows
              restaurant owners and operators to devote their attention to what
              truly matters—creating memorable dining experiences and serving
              exceptional food. By simplifying the complexities of restaurant
              management, we free you to focus on culinary excellence and
              customer delight.
            </p>
          </section>

          <section className=" promiseSection">
            <h3>Our Promise</h3>
            <p>
              At ServeSavvy, we promise unwavering commitment to innovation,
              reliability, and customer support. We understand the fast-paced,
              ever-evolving nature of the restaurant industry and the critical
              role that cutting-edge technology plays in its success. By
              choosing us, you gain more than just a service provider—you gain a
              partner who is deeply invested in your success and well-versed in
              the dynamics of the restaurant business. We are constantly
              refining our application, drawing on user feedback and the latest
              industry trends to ensure our solutions not only meet but exceed
              your expectations. Our goal is to continually enhance our software
              to help you stay ahead in a competitive market, ensuring that
              every feature we offer contributes to your efficiency and growth.
              Trust us to be with you every step of the way, as you transform
              challenges into opportunities and visions into realities.
              <br /> <br /> This expanded description aims to resonate more
              deeply with prospective clients by detailing the specific benefits
              and commitments your company provides, setting the stage for a
              trusted and successful partnership.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePageAboutUs;
