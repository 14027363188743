import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../../Utils/Navigation/Navigation";
import "./styles/Home.css";
import HomePageHero from "./HomePageHero";
import BusinessImpactStrip from "./BusinessBenefitsStrip";
import HomePageAboutUs from "./HomePageAboutUs";
import ContactUs from "./ContactUs";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import Footer from "../../Utils/Footer/Footer";

function Home() {
  return (
    <div className="Home">
      <Navigation />
      <ScrollToTop />

      <HomePageHero />
      <BusinessImpactStrip />
      <HomePageAboutUs />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
