import React from "react";
import "./styles/BusinessImpactStrip.css";

const BusinessImpactStrip = () => {
  const smallBenefits = [
    {
      title: "Quick Setup",
      description:
        "Deploy our solution swiftly with an intuitive onboarding process that minimizes downtime. Start reaping the benefits from day one and see immediate improvements in efficiency.",
    },
    {
      title: "User-Friendly Interface",
      description:
        "Experience a sleek, modern design that simplifies everyday operations. Our interface empowers your team to manage tasks effortlessly, so you can focus on delighting your customers.",
    },
    {
      title: "24/7 Support",
      description:
        "Enjoy round-the-clock access to our dedicated support team. With expert help always available, you can keep your operations running smoothly and address issues instantly.",
    },
  ];

  return (
    <section className="HomePage-business-impact-strip">
      <div className="HomePage-container">
        <div className="HomePage-section-header">
          <h2 className="HomePage-section-heading">Empower Your Business</h2>
          <p className="HomePage-section-subheading">
            Harness innovative technology to elevate your restaurant operations.
          </p>
        </div>

        <div className="HomePage-small-boxes">
          {smallBenefits.map((item, index) => (
            <div key={index} className="HomePage-small-box">
              <h3 className="HomePage-small-box-title">{item.title}</h3>
              <p className="HomePage-small-box-description">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        <div className="HomePage-two-column-section">
          <div className="HomePage-text-column">
            <h3 className="HomePage-section-title">
              Enhance Operational Efficiency
            </h3>
            <p className="HomePage-section-description">
              Eliminate bottlenecks and streamline your restaurant’s workflow by
              automating routine tasks. Our solution minimizes manual errors and
              frees up valuable time, allowing you to focus on delivering
              exceptional service and boosting revenue.
            </p>
          </div>
          <div className="HomePage-big-box">
            <h3 className="HomePage-big-box-title">
              Automated Order Management
            </h3>
            <p className="HomePage-big-box-description">
              Revolutionize your order processing with our fully automated
              system. From real-time tracking to error-free execution, our
              technology ensures that every order is handled swiftly and
              accurately, leading to faster service and higher customer
              satisfaction.
            </p>
          </div>
        </div>

        <div className="HomePage-two-column-section">
          <div className="HomePage-big-box">
            <h3 className="HomePage-big-box-title">Data-Driven Insights</h3>
            <p className="HomePage-big-box-description">
              Harness the power of real-time analytics to gain a deep
              understanding of your restaurant's performance. Our comprehensive
              dashboard turns raw data into actionable insights, helping you
              identify trends, optimize operations, and significantly boost
              profitability.
            </p>
          </div>
          <div className="HomePage-text-column">
            <h3 className="HomePage-section-title">Optimize Your Strategy</h3>
            <p className="HomePage-section-description">
              Leverage our analytics to fine-tune your menu, marketing, and
              overall business strategy. With clear, data-backed insights at
              your fingertips, you can confidently make decisions that drive
              sustained growth and give you a competitive edge.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessImpactStrip;
