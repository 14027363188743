import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles/HomePageHero.css";

// Use your downloaded image for now as a placeholder
const AnalyticsWebp = require("../../Images/Home/Analytics.webp");
const CustomerWebp = require("../../Images/Home/Customer.webp");
const EasyOrderWebp = require("../../Images/Home/EasyOrder.webp");
const LiveTrackingWebp = require("../../Images/Home/LiveTracking.webp");
const MenuWebp = require("../../Images/Home/Menu.webp");
const PaymentWebp = require("../../Images/Home/Payment.webp");
const RewardsWebp = require("../../Images/Home/Rewards.webp");
const defaultFeatureImage = require("../../Images/draft.webp");

const featureItems = [
  {
    _id: 1,
    title: "Easy Order Placement",
    description:
      "Simplify ordering with an intuitive process that minimizes clicks and enhances speed—ensuring your customers can place orders effortlessly and return time after time.",
  },
  {
    _id: 2,
    title: "Live Tracking",
    description:
      "Keep customers in the loop with real-time updates—from preparation to delivery—fostering trust and providing complete transparency throughout the process.",
  },
  {
    _id: 3,
    title: "Comprehensive Analytics",
    description:
      "Unlock powerful insights with detailed reports on sales trends, customer behavior, and operational performance, so you can make data-driven decisions to grow your business.",
  },
  {
    _id: 4,
    title: "Menu Management",
    description:
      "Effortlessly update and customize your menu with an easy-to-use interface that lets you add new dishes, adjust pricing, and showcase high-quality images to captivate your audience.",
  },
  {
    _id: 5,
    title: "Customer Engagement",
    description:
      "Boost loyalty by sending personalized promotions, exclusive deals, and timely notifications—keeping your customers informed and excited to return.",
  },
  {
    _id: 6,
    title: "Loyalty & Rewards",
    description:
      "Build lasting relationships with an integrated rewards system that incentivizes repeat business and makes every visit more valuable.",
  },
  {
    _id: 7,
    title: "Seamless Payment Options",
    description:
      "Provide a range of secure, fast, and reliable payment methods that enhance the checkout experience and keep transactions smooth.",
  },
];

const HomePageHero = () => {
  const trainRef = useRef(null);
  const boxesRef = useRef(null);
  const offsetRef = useRef(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let req;
    let lastTime = performance.now();
    // Measure the width of one set of boxes (first copy)
    let boxWidth = boxesRef.current ? boxesRef.current.offsetWidth : 0;
    const speed = 0.05; // pixels per ms

    const animate = (time) => {
      const deltaTime = time - lastTime;
      lastTime = time;
      if (!isPaused) {
        offsetRef.current -= speed * deltaTime;
        // When one copy has scrolled completely, reset offset
        if (offsetRef.current <= -boxWidth) {
          offsetRef.current = 0;
        }
        if (trainRef.current) {
          trainRef.current.style.transform = `translateX(${offsetRef.current}px)`;
        }
      }
      req = requestAnimationFrame(animate);
    };
    req = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(req);
  }, [isPaused]);

  const BoxImage = (feature) => {
    let imageElement = (
      <img
        className="feature-image"
        src={defaultFeatureImage}
        alt={feature.title}
      />
    );
    switch (feature._id) {
      case 1:
        imageElement = (
          <img
            className="feature-image"
            src={EasyOrderWebp}
            alt={feature.title}
          />
        );

        break;
      case 2:
        imageElement = (
          <img
            className="feature-image"
            src={LiveTrackingWebp}
            alt={feature.title}
          />
        );
        break;
      case 3:
        imageElement = (
          <img
            className="feature-image"
            src={AnalyticsWebp}
            alt={feature.title}
          />
        );
        break;
      case 4:
        imageElement = (
          <img className="feature-image" src={MenuWebp} alt={feature.title} />
        );
        break;
      case 5:
        imageElement = (
          <img
            className="feature-image"
            src={CustomerWebp}
            alt={feature.title}
          />
        );
        break;
      case 6:
        imageElement = (
          <img
            className="feature-image"
            src={RewardsWebp}
            alt={feature.title}
          />
        );
        break;
      case 7:
        imageElement = (
          <img
            className="feature-image"
            src={PaymentWebp}
            alt={feature.title}
          />
        );
        break;
      default:
        imageElement = (
          <img
            className="feature-image"
            src={defaultFeatureImage}
            alt={feature.title}
          />
        );
        break;
    }

    return imageElement;
  };

  return (
    <section className="homepage-hero">
      <div className="hero-overlay">
        <div className="hero-content">
          <div className="hero-left">
            <h1 className="hero-title">
              Transform Your Restaurant’s Operations
            </h1>
          </div>
          <div className="hero-right">
            <p className="hero-description">
              Boost efficiency and customer satisfaction with cutting-edge
              technology that streamlines orders, provides real-time tracking,
              delivers actionable analytics, and simplifies menu management.
              Empower your business with tools designed to drive growth and
              loyalty.
            </p>
            <div className="hero-buttons">
              <Link to="/free-trial" className="cta-button primary">
                Start Free Trial
              </Link>
              <Link to="/schedule-demo" className="cta-button secondary">
                See It in Action
              </Link>
            </div>
          </div>
        </div>

        <div
          className="feature-train-container"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div className="feature-train" ref={trainRef}>
            {/* First copy */}
            <div className="feature-boxes" ref={boxesRef}>
              {featureItems.map((feature, index) => (
                <div key={index} className="feature-box">
                  <div className="feature-box-left">{BoxImage(feature)}</div>
                  <div className="feature-box-right">
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* Duplicate copy */}
            <div className="feature-boxes">
              {featureItems.map((feature, index) => (
                <div
                  key={`dup-${index}`}
                  className={`feature-box ${
                    index === 0 ? "first-feature-box" : ""
                  }`}
                >
                  <div className="feature-box-left">{BoxImage(feature)}</div>
                  <div className="feature-box-right">
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHero;
