import React, { useState, useEffect } from "react";
import Navigation from "../../Utils/Navigation/Navigation";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import InfoStrip from "./InfoStrip";
import Footer from "../../Utils/Footer/Footer";
import Loading from "../../Loading";

function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessLocation: "",
    packageOption: "",
    company: "",
    specialFeature: "",
    purposeForInquiry: "",
    preferredContactMethod: "",
    preferredContactTime: "",
    additionalInformation: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }

  return (
    <div className="ContactUs">
      <div className="ContactUsContainer">
        <div className="WhyUs">
          <h1>Why work with ServeSavvy?</h1>
          <p>
            ServeSavvy uses cutting-edge technology to optimize restaurant
            operations with a mobile app tailored for both customers and
            administrators.
          </p>
          <div className="WhyUsPoints">
            <img
              src={require("../../Images/ContactUs/business.webp")}
              alt="about business"
            />
            <h3>Advantages of a Mobile App</h3>
            <p>
              Mobile applications are transforming industries, including the
              restaurant sector, in today’s digital age.
            </p>
            <div className="underline"></div>
          </div>
          <div className="WhyUsPoints">
            <img
              src={require("../../Images/ContactUs/support.webp")}
              alt="support business"
            />
            <h3>Reliable Support</h3>
            <p>
              ServeSavvy is dedicated to innovation, reliability, and
              exceptional customer support.
            </p>
          </div>
          <div className="WhyUsPoints">
            <img
              src={require("../../Images/ContactUs/price2.webp")}
              alt="price business"
            />
            <h3>Boosting Restaurant Profits</h3>
            <p>
              In a fast-paced digital world, mobile apps are essential for
              enhancing convenience and increasing profits.
            </p>
          </div>
        </div>
        <div className="ContactUsForm">
          <form
            className="form"
            method="POST"
            name="ContactUs"
            data-netlify="true"
            onSubmit="submit"
          >
            {/* netlify-form -- START -- */}
            <input type="hidden" name="form-name" value="ContactUs" />
            <div hidden>
              <label>packageOption:</label>
              <input
                type="text"
                name="packageOption"
                value={formData.packageOption}
              />
            </div>
            <div hidden>
              <label>purposeForInquiry:</label>
              <input
                type="text"
                name="purposeForInquiry"
                value={formData.purposeForInquiry}
              />
            </div>
            <div hidden>
              <label>preferredContactMethod:</label>
              <input
                type="text"
                name="preferredContactMethod"
                value={formData.preferredContactMethod}
              />
            </div>
            <div hidden>
              <label>preferredContactTime:</label>
              <input
                type="text"
                name="preferredContactTime"
                value={formData.preferredContactTime}
              />
            </div>

            {/* netlify-form -- END -- */}
            <h2 className="FormTitle">Contact us </h2>
            <div className="FormParagraph">
              Please complete this form if you need help with extensive
              deployments, migration strategies, or inquiries about a proof of
              concept for your restaurant.
            </div>
            <div className="GroupOf2">
              <label className="rightSide">
                First name*
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="leftSide">
                Last name*
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="GroupOf2">
              <label className="rightSide">
                Business email address*
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="leftSide">
                Business phone number
                <input
                  type="tel"
                  name="phone"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="GroupOf2">
              <label className="rightSide">
                Location*
                <input
                  type="text"
                  name="businessLocation"
                  placeholder="Enter your business location"
                  value={formData.businessLocation}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="leftSide">
                Company*
                <input
                  type="text"
                  name="company"
                  placeholder="Enter business name"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <label className="formLabel">
              Select Package Option*
              <select
                name="packageOption"
                value={formData.packageOption}
                onChange={handleChange}
                required
              >
                <option value="">Select Option</option>
                <option value="Basic-Tier">Basic Tier (£1,500 per year)</option>
                <option value="Premium-Tier">
                  Premium Tier (£3,000 per year)
                </option>
                <option value="Enterprise-Tier">
                  Enterprise Tier (£5,000 per year)
                </option>
              </select>
            </label>

            <label className="formLabel">
              Is it any special feature you want to add?
              <input
                type="text"
                name="specialFeature"
                placeholder="Enter any special feature you want to add"
                value={formData.specialFeature}
                onChange={handleChange}
              />
            </label>
            <label className="formLabel">
              What is the main purpose for inquiry*
              <select
                name="purposeForInquiry"
                value={formData.purposeForInquiry}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                <option value="General-Inquiry">General Inquiry</option>
                <option value="Package-Details">Package Details</option>
                <option value="Pricing-Information">Pricing Information</option>
                <option value="Feature-Customization">
                  Feature Customization
                </option>
                <option value="Support-Inquiry">Support Inquiry</option>
                <option value="Other-Inquiry">
                  Other, I will share more information bellow
                </option>
              </select>
            </label>
            <label className="formLabel">
              What is your preferred contact method?*
              <select
                name="preferredContactMethod"
                value={formData.preferredContactMethod}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
                <option value="Either">Either</option>
              </select>
            </label>
            <label className="formLabel">
              What is your preferred contact time?
              <select
                name="preferredContactTime"
                value={formData.preferredContactTime}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Morning">Morning (9 am - 12 pm)</option>
                <option value="Afternoon">Afternoon (12 pm - 5 pm)</option>
                <option value="Evening">Evening (5 pm - 8 pm))</option>
              </select>
            </label>
            <label className="formLabel">
              Please provide any additional information or requests you have.
              <textarea
                name="additionalInformation"
                placeholder="Enter any additional details or specific requests here."
                value={formData.additionalInformation}
                onChange={handleChange}
              />
            </label>
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
